import { Button } from '@mui/material'
import styled from 'styled-components'

export const MainContainer = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
`

export const Title = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 42px;
	color: #e5ecf5;

	${props => props.theme.breakpoints.down('xl')} {
		font-size: 32px;
	}
`

export const ListingDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
`

export const PreviewGrid = styled.div`
	min-height: 94px;

	display: grid;

	grid-template-columns: repeat(auto-fill, minmax(94px, 1fr));

	grid-row-gap: 12px;

	grid-column-gap: 4px;
`

export const PreviewImageContainer = styled.div<{ disabled: boolean }>`
	max-width: 94px;
	max-height: 94px;
	overflow: hidden;

	opacity: ${props => (props.disabled ? '0.5' : 1)};

	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
	transition: all 0.5s ease-in-out;

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
		transform: translate3d(0px, -2px, 0px);
	}
`

export const PreviewImage = styled.img`
	max-width: 100%;
	max-height: 100%;
`

export const MiddleSection = styled.div`
	margin-top: 24px;
	display: flex;
`

export const MiddleRightActions = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
`

export const Subtitle = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	color: #e5ecf5;
`

export const MiddleContainer = styled.div`
	flex: 1;
	max-width: 140px;
	margin: 0 24px;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const StyledButton = styled(Button)<{ disabled?: boolean }>`
	&& {
		font-family: 'Pixelade';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		height: 50px;
		background: ${props =>
			props.disabled
				? 'rgba(137, 168, 207, 0.8)'
				: 'linear-gradient(89.97deg, #88A1C1 -77.6%, #3F608C 56.9%, #2E5180 88.51%, #0B59C4 114.84%)'};
		border: 1px solid #89a8cf;
		box-sizing: border-box;
		color: ${props => (props.disabled ? '#18283D' : '#A0BFE4')};

		&:hover {
			background: ${props =>
				props.disabled ? 'rgba(137, 168, 207, 0.8)' : 'rgba(137, 168, 207, 0.8)'};
			color: ${props => (props.disabled ? '#18283D' : '#A0BFE4')};
		}
	}
`

export const ListingTimeDescription = styled(Title)`
	font-size: 25px;
`

export const ListingTimeSection = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 2px 0;
`

export const ToastLink = styled.a`
	color: #fff;

	&:hover {
		color: #fff;
		opacity: 0.6;
	}
`
