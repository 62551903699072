import client from 'axios'

export const baseURL = 'https://api.illiquidly.io/nft-content-api-1.0/'

export const axios = client.create({
	baseURL,
})

export const migratorURL = 'https://api.illiquidly.io/migrator/'

export const migratorClient = client.create({
	baseURL: migratorURL,
})
