import styled from 'styled-components'

export const MainContainer = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
`

export const Title = styled.div`
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: normal;
	font-size: 42px;
	color: #e5ecf5;
`

export const TradingContainer = styled.div`
	display: flex;
	flex-direction: row;

	${props => props.theme.breakpoints.down('xl')} {
		margin-top: 14px;
	}

	${props => props.theme.breakpoints.up('xl')} {
		margin-top: 32px;
	}
`

export const TradingCard = styled.div`
	flex-direction: row;
	background: #18283d;
	display: flex;
	padding: 16px;
`

export const MiddleSectionContainer = styled.div`
	padding: 48px 48px;
	${props => props.theme.breakpoints.down('xl')} {
		padding: 24px 24px;
	}
	margin-top: 96px;
`

export const ToastLink = styled.a`
	color: #fff;

	&:hover {
		color: #fff;
		opacity: 0.6;
	}
`
