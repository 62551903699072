import { useWallet } from '@terra-money/use-wallet'
import React from 'react'
import pMap from 'p-map'
import {
	getAllTrades,
	Trade,
	TradeState,
} from 'utils/blockchain/real/contracts/p2pTrading'
import cw721Contract from 'utils/blockchain/real/contracts/cw721'
import { keysToCamel } from 'utils/js/keysToCamel'
import { amountConverter } from 'utils/blockchain/terraUtils'
import { isEmpty, last } from 'lodash'
import { TradeResponse } from 'pages/listing-details/hooks/useTradeInfo'
import { asyncAction } from 'utils/js/asyncAction'

interface UseTrades {
	states?: TradeState[] | undefined
	owner?: string | undefined
	startAfter?: number | undefined
	limit?: number | undefined
	whitelistedUser?: string
	wantedNFT?: string
	containsToken?: string
	counterer?: string
	hasWhitelist?: boolean
}

// TODO: fetch all recursively, this just temporarily, implement infinite scroll later and move it to backend/indexer.
const fetchAllTradesUntilEnd = async (values: UseTrades): Promise<Trade[]> => {
	let result: Trade[] = []

	const fetchAllTradesRecursive = async ({
		states,
		owner,
		startAfter,
		limit,
		whitelistedUser,
		wantedNFT,
		containsToken,
		counterer,
		hasWhitelist,
	}: UseTrades): Promise<any> => {
		const [error, trades] = await asyncAction(
			getAllTrades(
				states,
				owner,
				startAfter,
				limit,
				whitelistedUser,
				wantedNFT,
				containsToken,
				counterer,
				hasWhitelist
			)
		)

		const tradesResponse = trades as Trade[]

		if (error) {
			return tradesResponse
		}

		result = [...result, ...tradesResponse.filter(x => x.tradeInfo)]

		if (tradesResponse.length > 0) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			return fetchAllTradesRecursive({
				states,
				owner,
				startAfter: last(tradesResponse)?.tradeId,
				limit,
				whitelistedUser,
				wantedNFT,
				containsToken,
				counterer,
				hasWhitelist,
			})
		}

		return tradesResponse
	}

	await fetchAllTradesRecursive(values)

	return result
}

export default function useTrades({
	states,
	owner,
	whitelistedUser,
	wantedNFT,
	containsToken,
	counterer,
	hasWhitelist = undefined,
}: UseTrades): [TradeResponse[], boolean] {
	const wallet = useWallet()
	const [trades, setTrades] = React.useState<any[]>([])
	const [isLoading, setLoading] = React.useState(true)

	const queryTrades = async () => {
		setLoading(true)
		const myAddress = wallet.wallets[0]?.terraAddress

		const tradesResponse = await fetchAllTradesUntilEnd({
			states,
			owner,
			whitelistedUser,
			wantedNFT,
			containsToken,
			limit: 30,
			counterer,
			hasWhitelist,
		})

		// TODO: This all heavy data processing should be moved on backend later
		const parsedTrades = await pMap(
			tradesResponse,
			async trade => {
				const cw721s = Object.values(
					trade.tradeInfo.associatedAssets.filter(
						x => x?.cw721Coin && !isEmpty(x?.cw721Coin)
					)
				).flatMap(a => Object.values(a))

				const cw20s = Object.values(
					trade.tradeInfo.associatedAssets.filter(x => x?.cw20 && !isEmpty(x?.cw20))
				).flatMap(a => Object.values(a))

				const cw721sExtended = await pMap(
					cw721s,
					async cw721 => {
						const contractInfo = await cw721Contract.memoizedGetContractInfo(
							cw721.address as string
						)

						const nftInfo = keysToCamel(
							await cw721Contract.memoizedGetNFTInfo(
								cw721.address as string,
								cw721.tokenId as string
							)
						)

						const imageUrl = encodeURI(
							(nftInfo?.extension?.image ?? '').replace(
								'ipfs://',
								'https://d1mx8bduarpf8s.cloudfront.net/'
							)
						)

						return {
							traits: (nftInfo?.extension?.attributes ?? []).map(
								({ traitType, value }: { traitType: string; value: string }) => [
									traitType,
									value,
								]
							),
							...cw721,
							collectionName: contractInfo.name,
							...nftInfo,
							name: nftInfo.extension?.name ?? '',
							tokenId: cw721.tokenId,
							contractAddress: cw721.address,
							imageUrl,
						}
					},
					{ concurrency: 10 }
				)
				return {
					...trade,
					...trade.tradeInfo,
					cw721s: cw721sExtended,
					cw20s,
					isMine: trade.tradeInfo.owner === myAddress,
					funds: trade.tradeInfo.associatedAssets
						.filter(x => x.coin && !isEmpty(x.coin))
						.map(fund => ({
							...fund,
							userfacingAmount: amountConverter[
								{ uusd: 'ust', uluna: 'luna' }[fund.coin?.denom || ''] || ''
							].blockchainValueToUserFacing(fund.coin?.amount),
							userfacingDenom: { uusd: 'UST', uluna: 'Luna' }[fund.coin?.denom || ''],
						})),
					lookingFor: await pMap(
						trade?.tradeInfo?.additionnalInfo?.nftsWanted ?? [],
						async address => {
							const contractInfo = await cw721Contract.memoizedGetContractInfo(
								address as string
							)

							return contractInfo.name
						},
						{ concurrency: 10 }
					),
				}
			},
			{ concurrency: 10 }
		)

		setTrades(parsedTrades)
		setLoading(false)
	}

	React.useEffect(() => {
		queryTrades()
	}, [
		wallet.network,
		counterer,
		JSON.stringify(states), // Stringify states otherwise it wont trigger work properly on filter change.
		owner,
		whitelistedUser,
		wantedNFT,
		containsToken,
		hasWhitelist,
	])

	return [trades, isLoading]
}
