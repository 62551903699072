import React from 'react'
import { appLoadingState } from 'App'
import Container from 'components/Container/Container'
import { VerticalGap } from 'components/Layout/Layout.styled'
import { Formik } from 'formik'
import useBroadcastingTx from 'hooks/useBroadcastingTx'
import useMyAssets from 'hooks/useMyAssets'
import moment from 'moment'
import OfferCard from 'pages/listing-details/components/OfferCard/OfferCard'
import useTradeInfo from 'pages/listing-details/hooks/useTradeInfo'
import { NFT } from 'pages/trading/components/TradeCard/TradeCard'
import YourNFTs from 'pages/trading/components/YourNFTs/YourNFTs'
import { TradingForm } from 'pages/trading/Trading'

import { useHistory, useRouteMatch } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { ReactComponent as Sync } from 'theme/icons/sync.svg'
import { TxReceipt } from 'utils/blockchain/blockchain.interface'
import { listCounterTradeOffer } from 'utils/blockchain/real/contracts/p2pTrading'
import { asyncAction } from 'utils/js/asyncAction'
import { useWallet, WalletStatus } from '@terra-money/use-wallet'
import useTransactionError from 'hooks/useTransactionError'
import { StyledLoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay'
import useVerifiedNFTs from 'hooks/useVerifiedCollections'
import { toast } from 'react-toastify'
import { noop } from 'lodash'
import CounterOfferCard from './components/CounterOfferCard/CounterOfferCard'
import {
	ListingDetailsContainer,
	ListingTimeDescription,
	ListingTimeSection,
	MainContainer,
	MiddleContainer,
	MiddleRightActions,
	MiddleSection,
	PreviewGrid,
	PreviewImage,
	PreviewImageContainer,
	StyledButton,
	Title,
	ToastLink,
} from './CounterOffer.styled'

const renderToastContent = ({ url }: { url?: string }) => (
	<div>
		<div>Successfully broadcasted.</div>
		<VerticalGap gap={8} />
		<ToastLink href={url} target='_blank' rel='noreferrer'>
			Open in Terra Finder
		</ToastLink>
	</div>
)

export default function CounterOffer() {
	const [verifiedCollections] = useVerifiedNFTs()
	const [showTransactionError] = useTransactionError()
	const history = useHistory()

	const wallet = useWallet()

	const myAddress = wallet.wallets[0]?.terraAddress

	const {
		fetchMyAssets,
		nfts,
		collections,
		funds,
		nftsPartiallyLoading: nftsLoading,
	} = useMyAssets()

	const [appLoading, setAppLoading] = useRecoilState(appLoadingState)

	const match = useRouteMatch<{ tradeId: string }>()

	const tradeId = +match.params.tradeId

	const [tradeInfo] = useTradeInfo(tradeId)

	const [previewNFT, setPreviewNFT] = React.useState<NFT | null>(null)

	React.useEffect(() => {
		setAppLoading(true)

		const [nft] = tradeInfo?.cw721s ?? []

		setPreviewNFT(nft ?? null)

		fetchMyAssets()
	}, [tradeInfo])

	const isP2P = (tradeInfo?.whitelistedUsers ?? []).length > 0

	const offeredDate = moment(
		Math.round(+(tradeInfo?.additionnalInfo?.time ?? 0) / 1000000)
	).format('DD.MM.YYYY')

	const [txReceipt, setTxReceipt] = React.useState<TxReceipt | null>(null)

	const onSuccessBroadcast = async () => {
		toast.success(renderToastContent({ url: txReceipt?.txTerraFinderUrl }), {
			position: 'top-right',
			autoClose: false,
			onClick: noop,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			pauseOnFocusLoss: false,
		})
		history.push(`/explore/details/${tradeId}`)
	}

	const { setLoading, loading } = useBroadcastingTx(
		txReceipt?.txId,
		onSuccessBroadcast
	)

	const onSubmit = async ({
		tradingNFTs,
		tradingAmountUST,
		tradingAmountLuna,
		lookingForComments,
	}: TradingForm) => {
		setAppLoading(true)
		setLoading({ ...loading, send: true })

		const [error, txResponse] = await asyncAction(
			listCounterTradeOffer({
				tradeId,
				amountUST: tradingAmountUST.replace(',', '.'),
				amountLuna: tradingAmountLuna.replace(',', '.'),
				comment: lookingForComments,
				cw721Tokens: tradingNFTs,
			})
		)

		if (txResponse) {
			setTxReceipt(txResponse)
		}
		if (error) {
			showTransactionError(error)
			setAppLoading(false)
		}
		setLoading({ ...loading, send: false })
	}

	return (
		<Formik
			onSubmit={onSubmit}
			validateOnMount
			initialValues={{
				tradingNFTs: [],
				tradingAmountUST: '',
				tradingAmountLuna: '',
				lookingForAssets: [],
				lookingForWhitelistedAddress: '',
				lookingForComments: '',
			}}
		>
			{({ submitForm, isSubmitting, isValid }) => (
				<StyledLoadingOverlay
					classNamePrefix='Primary_'
					flex
					active={appLoading}
					spinner
				>
					<MainContainer>
						<Container>
							<Title>making offer for:</Title>
							<ListingDetailsContainer>
								<ListingTimeSection>
									<ListingTimeDescription>{`Listed since: ${offeredDate}`}</ListingTimeDescription>
								</ListingTimeSection>
								<OfferCard
									verified={verifiedCollections.some(
										collection =>
											collection.collectionAddress === previewNFT?.contractAddress
									)}
									isP2P={isP2P}
									isMine={tradeInfo?.isMine}
									funds={tradeInfo?.funds ?? []}
									status={tradeInfo?.state ?? ''}
									comments={tradeInfo?.additionnalInfo?.ownerComment?.comment ?? ''}
									lookingFor={tradeInfo?.lookingFor ?? []}
									nft={{
										...previewNFT,
										owner: tradeInfo?.owner ?? '',
									}}
								/>
								<MiddleSection>
									<div style={{ flex: 1 }}>
										<VerticalGap gap={8} />
										<PreviewGrid>
											{tradeInfo?.cw721s.map(nft => (
												<PreviewImageContainer
													disabled={JSON.stringify(previewNFT) === JSON.stringify(nft)}
													key={JSON.stringify(nft)}
													onClick={() => setPreviewNFT(nft)}
												>
													<PreviewImage
														src={
															nft?.imageUrl || 'https://via.placeholder.com/500/000000/000000/'
														}
														alt={nft?.name}
													/>
												</PreviewImageContainer>
											))}
										</PreviewGrid>
									</div>
									<MiddleContainer>
										<div style={{ transform: 'rotate(-90deg)' }}>
											<Sync width={48} height={48} fill='#89A8CF' />
										</div>
									</MiddleContainer>
									<MiddleRightActions />
								</MiddleSection>
								<CounterOfferCard
									verifiedCollections={verifiedCollections}
									funds={funds}
								/>
								<MiddleSection>
									<MiddleRightActions>
										{wallet.status === WalletStatus.WALLET_NOT_CONNECTED && (
											<StyledButton onClick={() => wallet.connect()}>
												Connect wallet
											</StyledButton>
										)}
										{wallet.status === WalletStatus.WALLET_CONNECTED && (
											<StyledButton
												disabled={
													isSubmitting || !isValid || myAddress === tradeInfo?.owner
												}
												onClick={submitForm}
											>
												Make offer
											</StyledButton>
										)}
									</MiddleRightActions>
								</MiddleSection>

								<YourNFTs
									isLoading={nftsLoading}
									nfts={nfts}
									collections={collections}
									verifiedCollections={verifiedCollections}
								/>
							</ListingDetailsContainer>
						</Container>
					</MainContainer>
				</StyledLoadingOverlay>
			)}
		</Formik>
	)
}
