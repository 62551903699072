import { useWallet } from '@terra-money/use-wallet'
import React from 'react'
import {
	Asset,
	getCounterTrades,
	getTradeInfo,
	Trade,
	TradeInfo,
} from 'utils/blockchain/real/contracts/p2pTrading'

import cw721Contract from 'utils/blockchain/real/contracts/cw721'

import { keysToCamel } from 'utils/js/keysToCamel'
import { amountConverter } from 'utils/blockchain/terraUtils'
import { asyncAction } from 'utils/js/asyncAction'
import pMap from 'p-map'
import { useRecoilState } from 'recoil'
import { appLoadingState } from 'App'
import { NFT } from 'pages/trading/components/TradeCard/TradeCard'
import { isEmpty } from 'lodash'

export interface TradeResponse extends TradeInfo {
	cw721s: NFT[]
	cw20s: {
		address: string
		amount?: number | undefined
		tokenId?: string | undefined
	}[]
	isMine: boolean
	funds: {
		userfacingAmount: any
		userfacingDenom: string
		denom: string
		amount: number
	}[]
	lookingFor: string[]
	traits: [string, string][]
	counterId?: number
	tradeId: number
}

export default function useTradeInfo(
	tradeId: number
): [TradeResponse | null, TradeResponse[], () => Promise<void>, boolean] {
	const wallet = useWallet()

	const [tradeInfo, setTradeInfo] = React.useState<TradeResponse | null>(null)

	const [counterTrades, setCounterTrades] = React.useState<any[]>([])

	const [loading, setLoading] = useRecoilState(appLoadingState)

	const parseTradeResponse = async (response: TradeInfo) => {
		const myAddress = wallet.wallets[0]?.terraAddress

		const cw721s = Object.values(
			response.associatedAssets.filter(x => x?.cw721Coin && !isEmpty(x?.cw721Coin))
		).flatMap(x => Object.values(x))

		const cw20s = Object.values(
			response.associatedAssets.filter(x => x?.cw20 && !isEmpty(x?.cw20))
		).flatMap(x => Object.values(x))

		const cw721sExtended = await pMap(
			cw721s,
			async cw721 => {
				const contractInfo = await cw721Contract.memoizedGetContractInfo(
					cw721.address as string
				)

				const nftInfo = keysToCamel(
					await cw721Contract.memoizedGetNFTInfo(
						cw721.address as string,
						cw721.tokenId as string
					)
				)

				const imageUrl = encodeURI(
					(nftInfo?.extension?.image ?? '').replace(
						'ipfs://',
						'https://d1mx8bduarpf8s.cloudfront.net/' // This will be replaced later by our backend.
					)
				)

				const traits = (nftInfo?.extension?.attributes ?? []).map(
					({ traitType, value }: { traitType: string; value: string }) => [
						traitType.replace('_', ' ').replace('-', ' '), // Replace _ - with space. So Its easier to read.
						value.replace('_', ' ').replace('-', ' '),
					]
				)

				return {
					...cw721,
					traits,
					collectionName: contractInfo.name,
					...nftInfo,
					name: nftInfo.extension?.name ?? '',
					tokenId: cw721.tokenId,
					contractAddress: cw721.address,
					imageUrl,
				}
			},
			{ concurrency: 10 }
		)
		const trade = {
			...response,
			cw721s: cw721sExtended,
			cw20s,
			tradeId,
			isMine: response.owner === myAddress,
			funds: response.associatedAssets
				.filter(x => x.coin && !isEmpty(x.coin))
				.map((fund: Asset) => ({
					...fund,
					userfacingAmount: amountConverter[
						{ uusd: 'ust', uluna: 'luna' }[fund.coin?.denom || ''] || ''
					].blockchainValueToUserFacing(fund.coin?.amount),
					userfacingDenom: { uusd: 'UST', uluna: 'Luna' }[fund.coin?.denom || ''],
				})),
			lookingFor: await pMap(
				response.additionnalInfo?.nftsWanted ?? [],
				async address => {
					const contractInfo = await cw721Contract.memoizedGetContractInfo(
						address as string
					)

					return contractInfo.name
				},
				{ concurrency: 10 }
			),
		}

		return trade
	}

	const fetchTradeInfo = async () => {
		setLoading(true)

		const [, tradeResponse]: [any, TradeInfo] = (await asyncAction(
			getTradeInfo(tradeId)
		)) as any

		if (tradeResponse) {
			const [, tradeExtended] = await asyncAction(
				parseTradeResponse(tradeResponse)
			)

			setTradeInfo(tradeExtended)
		}

		const [, counterTradesResponse]: [any, Trade[]] = (await asyncAction(
			getCounterTrades(tradeId)
		)) as any

		if (counterTradesResponse) {
			const [, counterTradesExtended] = await asyncAction(
				pMap(counterTradesResponse, async counterTrade => ({
					...(await parseTradeResponse(counterTrade.tradeInfo as TradeInfo)),
					counterId: counterTrade.counterId,
				}))
			)

			setCounterTrades(counterTradesExtended)
		}

		setLoading(false)
	}

	React.useEffect(() => {
		fetchTradeInfo()
	}, [wallet.network])

	return [tradeInfo, counterTrades, fetchTradeInfo, loading]
}
