import { useWallet } from '@terra-money/use-wallet'
import pMemoize from 'p-memoize'
import React from 'react'
import { axios } from 'services/Axios/Axios'
import { getNetworkName } from 'utils/blockchain/terraUtils'
import { Collection } from './useMyAssets'

type UseVerifiedNFTs = [Collection[], boolean]

const memoizedAxiosGet = pMemoize(axios.get)

export default function useVerifiedNFTs(): UseVerifiedNFTs {
	const [collections, setCollections] = React.useState<Collection[]>([])
	const [loading, setLoading] = React.useState(false)
	const wallet = useWallet()

	const fetchAvailableCollections = async () => {
		setLoading(true)
		const response = await memoizedAxiosGet(`/query/${getNetworkName()}/`)
		setCollections(
			Object.values(response.data).map(({ contract, name }: any) => ({
				collectionName: name,
				collectionAddress: contract,
			}))
		)
		setLoading(false)
	}

	React.useEffect(() => {
		fetchAvailableCollections()
	}, [wallet.network])

	return [collections, loading]
}
