import {
	getChainOptions,
	WalletControllerChainOptions,
	WalletProvider,
} from '@terra-money/wallet-provider'
import App from 'App'
import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { RecoilRoot } from 'recoil'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'

getChainOptions().then((chainOptions: WalletControllerChainOptions) => {
	ReactDOM.render(
		<WalletProvider {...chainOptions}>
			<RecoilRoot>
				<HashRouter>
					<App />
					<ToastContainer
						theme='dark'
						position='top-right'
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</HashRouter>
			</RecoilRoot>
		</WalletProvider>,
		document.getElementById('root')
	)
})
