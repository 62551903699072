import { IconButton } from '@mui/material'
import styled from 'styled-components'

export const SidebarContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	background: #18283d;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	width: 260px;
`

export const HeaderOverlay = styled.div`
	display: flex;
	z-index: 9;
	position: absolute;
	width: 100%;
	height: 197px;
`

export const SidebarHeader = styled.div`
	z-index: 99;
	height: 62px;
	display: flex;
`

export const ConnectionButton = styled(IconButton)`
	&& {
		display: flex;
		font-style: normal;
		font-weight: normal;
		border-radius: 0;
		padding: 0;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		&:hover: {
			background-color: transparent;
		}
	}
`

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	padding-right: 16px;
`

export const ButtonContentContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
`

export const ConnectionButtonText = styled.div`
	margin-left: 28px;
	font-family: 'Pixelade';
	font-style: normal;
	font-weight: 400;
	font-size: 26px;
	color: #e5ecf5;
`

export const SidebarBody = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px 44px;
	flex: 1;
`

export const SidebarSubheader = styled.div`
	padding-top: 24px;
	z-index: 99;
`

export const LogoContainer = styled.div`
	display: flex;
	width: 160px;
`

export const SectionText = styled.div`
	font-family: Biryani;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;

	color: ${(props: { disabled?: boolean }) =>
		props.disabled ? 'rgba(137, 168, 207, 0.44)' : '#89A8CF'};
`

export const StyledNavLink = styled.div<{
	active?: boolean
	disabled?: boolean
}>`
	&& {
		display: flex;
		font-family: 'Biryani';
		margin-bottom: 30px;

		cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
		color: ${props => (props.active ? '#E5ECF5' : '#89A8CF')};
		a {
			cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
			transition: color 350ms;
			color: ${props => (props.active ? '#E5ECF5' : '#89A8CF')};
			${props => props.theme.breakpoints.up('md')} {
				font-size: 18px;
				font-weight: 600;
			}
			${props => (props.disabled ? 'color: rgba(137, 168, 207, 0.44);' : '')}

			&:hover {
				${props => (props.disabled ? 'color: rgba(137, 168, 207, 0.44);' : '')}
			}
		}

		&:hover {
			${props => (props.disabled ? 'color: rgba(137, 168, 207, 0.44);' : '')}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`

export const SectionContainer = styled.div`
	margin-top: 85px;

	@media (max-height: 1000px) {
		margin-top: 40px;
	}

	@media (max-height: 800px) {
		margin-top: 20px;
	}
`

export const NavIconWrapper = styled.div`
	margin-right: 24px;
`

export const SidebarFooter = styled.div`
	margin-top: auto;
	display: flex;
	justify-content: space-between;
	padding: 0 0px;
	padding-bottom: 48px;
`

export const IconLink = styled.a`
	cursor: 'pointer';
`
