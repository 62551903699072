import { useWallet, WalletStatus } from '@terra-money/use-wallet'
import React from 'react'
import { atom, useRecoilState } from 'recoil'
import { axios } from 'services/Axios/Axios'
import { asyncAction } from 'utils/js/asyncAction'
import { keysToCamel } from 'utils/js/keysToCamel'
import pMap from 'p-map'
import cw721 from 'utils/blockchain/real/contracts/cw721'
import promiseRetry from 'promise-retry'
import { NFT } from 'pages/trading/components/TradeCard/TradeCard'
import {
	getBalanceLUNA,
	getBalanceUST,
	getNetworkName,
} from 'utils/blockchain/terraUtils'

export const myFundsState = atom({
	key: 'myFunds',
	default: {
		balanceUST: 0,
		balanceLuna: 0,
	},
})

export interface Collection {
	collectionName: string
	collectionAddress: string
}

enum NFTState {
	Full = 0, // All old txs were loaded
	Partial = 1, // There is still some old info to query to have a complete nft_interacted array
	isUpdating = 2, // THe API is currently updating info for the address
}

function useMyAssets() {
	const [funds, setFunds] = useRecoilState(myFundsState)
	const [nfts, setNfts] = React.useState<NFT[]>([])
	const [collections, setCollections] = React.useState<Collection[]>([])
	const wallet = useWallet()
	const [nftsPartiallyLoading, setNFTsPartiallyLoading] = React.useState(false)
	const [nftsFullyLoading, setNFTsFullyLoading] = React.useState(false)

	async function fetchMyBalances() {
		const balanceLuna = await getBalanceLUNA()

		const balanceUST = await getBalanceUST()

		setFunds({
			balanceLuna,
			balanceUST,
		})
	}

	async function setNFTs(oNfts = {}): Promise<void> {
		const ownerCW721sContractInfo = await pMap(
			Object.values(oNfts).filter((o: any) => Object.keys(o.tokens).length),
			async (contract: any) => {
				const contractInfo = await cw721.memoizedGetContractInfo(
					contract.contract as string
				)

				return {
					...contract,
					contractInfo,
				}
			},
			{ concurrency: 10 }
		)

		const ownedCW721s = ownerCW721sContractInfo.filter(x => x)

		setCollections(
			ownedCW721s.map(({ contractInfo, contract }: any) => ({
				collectionName: contractInfo?.name ?? '',
				collectionAddress: contract,
			}))
		)

		setNfts(
			keysToCamel(ownedCW721s).flatMap(({ contract, tokens, contractInfo }: any) =>
				Object.values(tokens).map(({ tokenId, nftInfo }: any) => ({
					tokenId: tokenId?.tokenId ? tokenId?.tokenId : tokenId,
					contractAddress: contract,
					...nftInfo,
					...nftInfo.extension,
					collectionName: contractInfo?.name ?? '',
					imageUrl: encodeURI(
						(nftInfo?.extension?.image ?? '').replace(
							'ipfs://',
							'https://d1mx8bduarpf8s.cloudfront.net/'
						)
					),
				}))
			)
		)
	}

	async function requestUpdateNFTs(network: string, address: string) {
		return axios.get(`query/${network}/${address}?action=update`)
	}

	async function requestNFTs(network: string, address: string) {
		return axios.get(`query/${network}/${address}?action=plain_db`)
	}

	async function fetchMyAssets() {
		setNFTsPartiallyLoading(true)
		setNFTsFullyLoading(true)

		const myAddress = wallet.wallets[0]?.terraAddress

		if (myAddress) {
			await asyncAction(fetchMyBalances())

			const [, partialNFTs] = await asyncAction(
				requestUpdateNFTs(getNetworkName(), myAddress)
			)

			if (partialNFTs?.data) {
				const { ownedTokens } = partialNFTs?.data ?? {}

				setNFTs(ownedTokens)
			}

			setNFTsPartiallyLoading(false)

			const [, fullNFTs] = await asyncAction(
				promiseRetry(
					{ minTimeout: 125, retries: 100, factor: 2, randomize: true },
					async retry => {
						const [error, response] = await asyncAction(
							requestNFTs(getNetworkName(), myAddress)
						)

						if (response?.data?.state === NFTState.isUpdating) {
							return retry("Try again, It's not ready yet!")
						}

						if (error) {
							return retry('Try again state unknown')
						}

						return response
					}
				)
			)

			if (fullNFTs?.data) {
				const { ownedTokens } = fullNFTs?.data ?? {}

				setNFTs(ownedTokens)
			}
		}
		setNFTsFullyLoading(false)
	}

	React.useEffect(() => {
		if (wallet.status === WalletStatus.WALLET_CONNECTED) {
			fetchMyAssets()
		}
	}, [wallet.connection, wallet.network])

	return {
		funds,
		nfts,
		collections,
		nftsPartiallyLoading,
		nftsFullyLoading,
		fetchMyAssets,
	}
}

export default useMyAssets
