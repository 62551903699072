import { useWallet, WalletStatus } from '@terra-money/use-wallet'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TradeState } from 'utils/blockchain/real/contracts/p2pTrading'
import useTrades from './useTrades'

export default function useNotifyWhitelistedTrades() {
	const wallet = useWallet()
	const myAddress = wallet.wallets[0]?.terraAddress
	const history = useHistory()

	const [trades] = useTrades({
		states: [TradeState.Published],
		whitelistedUser: myAddress ?? '-',
	})

	const notifyTrades = async () => {
		trades.forEach(trade => {
			// Notify whitelists
			toast.warn(`Please review whitelisted listing`, {
				toastId: trade.tradeId,
				position: 'top-right',
				autoClose: 4000,
				onClick: () => history.push(`/explore/details/${trade.tradeId}`),
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
				pauseOnFocusLoss: false,
			})
		})
	}

	React.useEffect(() => {
		if (wallet.status === WalletStatus.WALLET_CONNECTED) {
			notifyTrades()
		}
	}, [wallet.status, myAddress, trades])

	return []
}
